
        const accordionSections = document.querySelectorAll('.accordion__section');

        accordionSections.forEach((section) => {
            const title = section.querySelector('.accordion__title');
            const content = section.querySelector('.accordion__content');
            const arrow = section.querySelector('.accordion__arrow');

            title.addEventListener('click', () => {
                const active_section = document.querySelector('.accordion__active');

                if (active_section) {
                    const active_arrow = active_section.querySelector('.accordion__arrow');
                    const active_content = active_section.querySelector('.accordion__content');

                    active_section.classList.toggle('accordion__active');
                    active_arrow.classList.toggle('accordion__arrow--rotate');
                    active_content.style.maxHeight = '0';
                }
                if (active_section != section) {
                    section.classList.toggle('accordion__active');
                    arrow.classList.toggle('accordion__arrow--rotate');

                    content.style.maxHeight = content.scrollHeight + 'px';
                }
            });
        });